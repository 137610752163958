import { Link } from "react-router-dom";
import { routes } from "../../routing/routes";
import emptySVG from './404.svg';

export default function Error404() {
    return (<>
        <div className="position-absolute h-100" style={{ left: '0', right: '0', width: '100%', backgroundColor: 'var(--bs-draft)' }}>

            <div className="container-fluid position-relative svgBG mt-5" style={{ right: 'auto', left: 'auto', height: '90%', backgroundSize: 'auto', backgroundPosition: 'top center', backgroundImage: `url(${emptySVG})`, backgroundRepeat: 'no-repeat' }}>
                <div className="px-3 py-3 rounded-2 glass position-relative shadow-sm text-center" style={{ right: 'auto', left: 'auto', top: '0', width: '50%', marginRight: 'auto', marginLeft: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.35' }}>
                    <h3>You seem to be lost</h3>
                    <p>Click <Link to={routes.index}>here</Link> to return to the LCMS Learning Hub</p>
                </div>
            </div>

        </div>
    </>);
}