export const Spinner = (props: { delay?: string; size?: string; margin?: string; }) => {
    return (
        <div className="spinner-grow shadow-lg mb-1" role="status" style={{
            marginRight: props.margin || '0.5rem',
            marginLeft: props.margin || '0.5rem',
            width: props.size || '1.25rem',
            height: props.size || '1.25rem',
            animationDelay: props.delay
        }} ></div>
    );
}

