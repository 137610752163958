import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faTimes,
	faGripLines,
	faClipboard,
} from "@fortawesome/pro-light-svg-icons";

library.add(
	faTimes,
	faGripLines,
	faClipboard,
);
