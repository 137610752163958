import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export interface EndpointResponse {
    success: boolean;
    redirect: string | null;
}

export const logoutEndpoint = new ApiCall<EndpointResponse>().get('logout', {
    route: endpoints.logout
});