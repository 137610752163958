import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faHexagonExclamation,
    faTimes,
    faRotateLeft,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
    faHexagonExclamation,
    faTimes,
    faRotateLeft,
);
