import { notUndefined } from "@lcms/helpers";
import { Flex } from "@lcms/react-flex";
import { Spinner } from "./spinner";

export function Loading({ text, className }: { text?: string, className?: string; }) {
    return (
        <Flex justification="center" className={notUndefined(className) ? className : "h-100"}>
            <div className="align-self-center text-center">
                <div>
                    <Spinner delay="-.2s" />
                    <Spinner delay="-.1s" />
                    <Spinner />
                </div>
                <span className="display-5">
                    {text || 'Loading'}
                </span>
            </div>
        </Flex>
    );
}