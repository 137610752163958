import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faRss,
    faFile,
    faFolderOpen,
    faHome,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
    faRss,
    faFile,
    faFolderOpen,
    faHome,
);
