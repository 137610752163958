import { PluginConfiguration } from '.';

export const folderPlugin: PluginConfiguration = {
	icon: 'folder-open',
	name: 'Document Library - Folder',
	pluginName: 'Folder',
	url: 'document-library/Folder.min.js',
	documentation: 'document-library/index.html',
    properties: 'document-library/folder.properties.json'
};
