import { LoadingOverlayProvider } from '@lcms/react-loading-overlay';
import { LoadingOverlay } from 'src/features/loading-overlay';
import { Authentication } from 'src/features/authentication';
import { Routing } from 'src/routing/routing';
import { ToggleProvider } from 'src/features/toggles';
import { Scripts } from './scripts';
import { ConfirmationProvider } from '@lcms/react-modal';

export function App() {

  return (<>
    <Scripts />
    <LoadingOverlayProvider overlay={<LoadingOverlay />}>
      <Authentication>
        <ToggleProvider>
          <ConfirmationProvider>
            <Routing />
          </ConfirmationProvider>
        </ToggleProvider>
      </Authentication>
    </LoadingOverlayProvider>
  </>
  );
}

