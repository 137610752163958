import React from 'react';
import ReactDOM from 'react-dom';

import './style/index.scss';
import './style/font-awesome';
import 'bootstrap';

import { App } from './app';
import { CompatabilityCheck } from '@lcms/react-compatibility-check';

ReactDOM.render(
  <React.StrictMode>
    <CompatabilityCheck>
      <App />
    </CompatabilityCheck>
  </React.StrictMode>,
  document.getElementById('root')
);
