import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { RouteAsClass } from '@lcms/react-router-helpers';
import { AuthorizedRoute } from '@lcms/react-identity-routes';
import { routes } from './routes';

import IndexPage from '../pages/home';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
import NotFoundPage from '../pages/not-found';
import { guards } from './guards';
import { useReactiveSidebar } from '@lcms/react-sidebar';
import { useToggle } from 'src/features/toggles/use-toggle';

export function Routing() {

    const { set } = useToggle('side-bar');
    useReactiveSidebar(set);

    return (
        <BrowserRouter>
            <RouteAsClass />
            <Switch>
                <Route render={LogoutPage} exact path={routes.logout} />
                <Route render={LoginPage} exact path={routes.login} />
                <AuthorizedRoute render={IndexPage} path={routes.index + ':plugin?'} redirect={null} isAuthorized={guards[routes.index]} />
                <Route render={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    );
}


