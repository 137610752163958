import { FetchApi } from '@lcms/api-definition';
import { ApiKey } from './api-key';
import { notifyUnauthorized } from '@lcms/react-identity';
import { authenticationRouter } from './endpoints/authentication';
import { configurationRouter } from './endpoints/configuration';
import { configurationsRouter } from './endpoints/configurations';

export const api = FetchApi(ApiKey, notifyUnauthorized)
    .useRouter(authenticationRouter)
    .useRouter(configurationRouter)
    .useRouter(configurationsRouter)
