import { useMount } from "@lcms/react-helpers";
import { useState } from "react";
import { api } from "src/api";

const millisecondsPerMinute = 1000 * 60;
const pingInterval = 5 * millisecondsPerMinute;

export function useStayAliveOnActive() {
    const [isActive, setIsActive] = useState(true);

    useMount(() => {
        let updateState = true;
        let lastActivity = new Date();

        const handler = () => {
            if (updateState) {

                lastActivity = new Date();
                setIsActive(true);
                updateState = false;
                console.log('User activity noted', lastActivity)
            }
        };

        const interval = setInterval(() => {
            updateState = true;
            if (new Date().valueOf() - lastActivity.valueOf() < (pingInterval)) {
                console.log('User is active - extending session');
                api.session.stayAlive();
            } else {
                console.log('User is inactive')
                setIsActive(false);
            }
        }, (pingInterval));

        window.addEventListener('mousemove', handler);
        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('keydown', handler);
            clearInterval(interval);
        };

    });

    return {
        isActive
    }
}
