import { config } from "src/config";
import { Plugin } from ".";

export const feedsPlugin: Plugin = {
    name: 'Wordpress Feeds',
    pluginName: 'Feeds_WordPress',
    icon: 'rss',
    url: 'feeds/WordPress.min.js',
	documentation: 'feeds/index.html',
    additionalAttributes: {
        'data-api': config.feedsApi,
        'data-debug': 'true'
    },
    properties: {
        site: {
            required: true,
            values: ['reporter', 'calendar', 'resources', 'kfuo', 'witness', 'engage', 'international', 'youthesource', 'luthed', 'lcmslife'],
            description: 'The Wordpress site to pull the a feed from'
        },
        type: {
            required: true,
            values: ['single', 'feed', 'card', 'carousel'],
            description: 'The display format to render'
        },
        taxonomy: {
            values: ['category', 'tag', 'all'],
            description: 'The taxonomy type to filter the feed to'
        },
        taxonomyName: {
            description: 'The taxonomy value to use to filter',
            hint: 'statements, convention, people, etc'
        },
        noCta: {
            values: ['true', 'false'],
            description: 'Hide call-to-action link',
            show: values => ['card', 'carousel'].includes(values.type)
        },
        noImage: {
            values: ['true', 'false'],
            description: 'Hide image',
            show: values => ['single', 'card', 'carousel'].includes(values.type)
        },
        noTitle: {
            values: ['true', 'false'],
            description: 'Hide title',
            show: values => ['single', 'card', 'carousel'].includes(values.type)
        },
        noDate: {
            values: ['true', 'false'],
            description: 'Hide date',
            show: values => ['feed', 'card', 'carousel'].includes(values.type)
        },
        showPastEvents: {
            values: ['true', 'false'],
            description: 'Show events that have passed',
            show: values => ['calendar'].includes(values.site)
        },
        truncateDescription: {
            values: ['true', 'false'],
            description: 'Truncate Description',
            show: values => ['card', 'carousel'].includes(values.type)
        },
        noDescription: {
            values: ['true', 'false'],
            description: 'Hide description',
            show: values => ['card', 'carousel'].includes(values.type)
        },
        count: {
            description: 'The number of items to pull into the feed',
            hint: '1 - 10'
        },
        dateMode: {
            values: ['header', 'footer'],
            description: 'Date location',
            show: values => ['feed', 'card', 'carousel'].includes(values.type)
        },
        alignment: {
            values: ['left', 'right'],
            description: 'Image alignment',
            show: values => ['single'].includes(values.type)
        },
        useHostIcons: {
            values: ['true'],
            description: 'If true, rely on host site for supplying necessary icons'
        },
        error: {
            values: ['true', 'false'],
            description: 'Present as an error state'
        },
        loading: {
            values: ['true', 'false'],
            description: 'Present as a loading state'
        },
        scrollXs: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on xs screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollSm: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on sm screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollMd: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on md screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollLg: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on lg screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollXl: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on xl screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollXxl: {
            values: ['true', 'false'],
            description: 'Revert to scrollbar on xxl screensize',
            show: values => ['carousel'].includes(values.type)
        },
        scrollSpeed: {
            description: 'The number of pixels to scroll per click',
            hint: 'Enter a number',
            show: values => ['carousel'].includes(values.type)
        },
        buffer: {
            description: 'The number of pixels to buffer at the end of the carousel',
            hint: 'Enter a number',
            show: values => ['carousel'].includes(values.type)
        },
        columns: {
            description: 'The number of columns to display the feed in',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsXs: {
            description: 'The number of columns to display the feed on xs screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsSm: {
            description: 'The number of columns to display the feed on sm screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsMd: {
            description: 'The number of columns to display the feed on md screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsLg: {
            description: 'The number of columns to display the feed on lg screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsXl: {
            description: 'The number of columns to display the feed on xl screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },
        columnsXxl: {
            description: 'The number of columns to display the feed on xxl screen sizes',
            hint: 'Enter a number',
            show: values => ['feed', 'card', 'single'].includes(values.type)
        },

    }
}