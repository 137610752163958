import { AzureUser } from '@lcms/identity-microsoft';
import { routes } from '../routes'
import { AuthenticatedUser } from "@lcms/identity";

export function userRoute() {
    return (authentication: AuthenticatedUser<AzureUser> | null) => {
        if (!authentication) {
            return {
                redirect: routes.login,
                saveLocation: true
            };
        }
        return null;
    };
}
