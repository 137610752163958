import { ApiCall, OptionalParameterDefinition, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { Dictionary } from '@lcms/helpers';

export type EndpointResponse = { id: string | null; };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', { route: endpoints.saveConfiguration, params: {
    id: OptionalParameterDefinition.String,
    name: ParameterDefinition.String,
    notes: ParameterDefinition.String,
    plugin: ParameterDefinition.String,
    configuration: {
        type: {} as Dictionary<string>
    },
} });

export default saveEndpoint;