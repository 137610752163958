import { Flex } from "@lcms/react-flex";
import { Spinner } from "./spinner";

export function LoadingPage() {
    return (
        <div style={{ position: 'absolute', width: '100%', top: 50, bottom: 0 }}>
            <Flex justification="center" className="h-100">
                <div className="align-self-center text-center">
                    <div>
                        <Spinner delay="-.2s" />
                        <Spinner delay="-.1s"/>
                        <Spinner />
                    </div>
                    <span className="display-5 text-muted">
                        Loading
                    </span>
                </div>
            </Flex>
        </div>
    );
}