import { config } from 'src/config';
import { ExternalScript, InlineScript } from '@lcms/react-scripts';
import { plugins } from 'src/features/plugins';
import { useMemo } from 'react';

export function Scripts() {
  return (<>
    {config.scripts.map(script => (
      <ExternalScript async={false} key={script.resourceName} url={`${config.pluginSite}${config.testPath}${script.url}`} />
    ))}
    {plugins.map(plugin => (
      <ExternalScript async={false} key={plugin.name} url={plugin.url.startsWith('https://') ? plugin.url : `${config.pluginSite}${config.testPath}${plugin.url}`} />
    ))}
    <DependencyCheck />
  </>);
}

function DependencyCheck() {
  const script = useMemo(() =>
    `(function() {

    const startTime = new Date().valueOf();
    function check() {
        const hasUnloadedDependencies = [${config.scripts.map(script => 'typeof ' + script.resourceName).concat(plugins.map(plugin => 'typeof LCMS_' + plugin.pluginName)).join(', ')}].includes('undefined');
        if (hasUnloadedDependencies) {
            if (new Date().valueOf() - startTime < 1000 * 10)
              setTimeout(check, 100);
            else {
              console.error('Dependencies Failed To Load', [${config.scripts.map(script => `"${script.resourceName} = " + typeof ${script.resourceName}`)
              .concat(plugins.map(plugin => `"LCMS_${plugin.pluginName} = " + typeof LCMS_${plugin.pluginName}`)).join(', ')}])

              window.dependenciesLoaded = true; 
              window.dependenciesErrors = true; 
              window.dispatchEvent(new Event("x-dependencies-loaded"));
              window.dispatchEvent(new Event("x-dependencies-errors"));
            }
        } else {
            window.dependenciesLoaded = true; 
            window.dependenciesErrors = false; 
            window.dispatchEvent(new Event("x-dependencies-loaded"));
        }
    }
    check();
})()`, []);

  return <InlineScript type='text/javascript' code={script} />
}