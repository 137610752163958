import React, { Suspense } from 'react';
import { LoadingPage } from 'src/components/loading';

export function Lazy({ Page }: { Page: React.LazyExoticComponent<() => JSX.Element> }) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page />
        </Suspense>
    )
}
