import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Dictionary } from "@lcms/helpers";
import { feedsPlugin } from "./feeds";
import { filesPlugin } from "./files";
import { folderPlugin } from "./folder";

declare global {
    const ReactLCMS: {
        render: (element: HTMLElement) => void;
        unmount: (element: HTMLElement) => void;
    };
    let dependenciesLoaded: boolean;
    let dependenciesErrors: boolean;
}

export interface Plugin {
    name: string;
    icon: IconName;
    pluginName: string;
    properties: PluginProperties;
    url: string;
    additionalAttributes?: Dictionary<string>;
    documentation?: string;
}

export interface PluginConfiguration {
    name: string;
    icon: IconName;
    pluginName: string;
    properties: PluginProperties | string;
    url: string;
    additionalAttributes?: Dictionary<string>;
    documentation?: string;
}

export type PluginProperties = Dictionary<PluginProperty>

export interface PluginProperty {
    required?: boolean;
    show?: (values: Dictionary<string>) => boolean;
    values?: string[];
    description: string;
    hint?: string;
}

export const plugins: PluginConfiguration[] = [
    feedsPlugin,
    filesPlugin,
    folderPlugin
]