import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faRightFromBracket,
	faUser,
	faArrowsRotate,
	faCode,
	faTrash,
	faFloppyDisk,
	faFolder,
	faTrashCan,
	faSensorTriangleExclamation,
	faGearComplex,
	faPlusCircle,
	faTimes,
	faBook,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
	faRightFromBracket,
	faUser,
	faArrowsRotate,
	faCode,
	faTrash,
	faFloppyDisk,
	faFolder,
	faTrashCan,
	faSensorTriangleExclamation,
	faGearComplex,
	faPlusCircle,
	faTimes,
	faBook
);
