import { ApiCall, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { PluginConfigurationSummary } from '../../../types/plugin-configuration';

export type EndpointResponse = { configurations: PluginConfigurationSummary[]; };
export const getEndpoint = new ApiCall<EndpointResponse>().post('get', {
    route: endpoints.getConfigurations,
    params: {
        plugin: OptionalParameterDefinition.String
    }
});

export default getEndpoint;